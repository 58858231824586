import * as React from 'react'
import dynamic from 'next/dynamic'
import { Spacing } from '@therich-team/design-system'

import { useMobileSize } from '@/utils/browser/resize-hook'
import DynamicTradingview from '@/tradingview/dynamic-tradingview'
import ClientOnly from '@/components/common/client-only'
import useLanguage from '@/utils/language/language-hook'

import RecentStocks from './recent-stocks'
import MbtiBanner from './mbti-banner'
import RecommendedPortfolios from './recommended-portfolios'
import ThisWeekDividendStocks from './this-week-dividend-stocks'
import Portfolios from './home-portfolios'
// import PopularUsers from './home-popular-users'
import TodayNews from './today-news'
import Advertisement from './advertisement'
import StyledHome from './styled'
import Popups from './popups'
import InvestingInsights from './investing-insights'

const Quotes = dynamic(() => import('./quotes'), { ssr: false })

const Home = () => {
  const { isKorean } = useLanguage()
  const { isMobile } = useMobileSize()

  return (
    <StyledHome>
      <div className="--flex">
        <div className="--desktop-only chart">
          {!isMobile && (
            <ClientOnly>
              <DynamicTradingview symbol="SPY" chartName="SPY stock chart" />
            </ClientOnly>
          )}
        </div>
        <Spacing x="500" mobileX="0" />
        <div className="top-contents">
          <div className="portfolios">
            <Portfolios />
          </div>
          <Spacing y="0" mobileY="500" className="--mobile-only" />
          <div className="mbti">
            <MbtiBanner />
          </div>
          <Spacing y="300" mobileY="500" />
          <RecentStocks />
        </div>
      </div>
      <Spacing y="500" />
      <TodayNews />
      <Spacing y="500" />
      <InvestingInsights />
      <Spacing y="500" />
      <ThisWeekDividendStocks />
      <Spacing y="500" />
      {/* <PopularUsers /> */}
      {isKorean && (
        <>
          <Quotes />
          <Spacing y="500" />
        </>
      )}

      <RecommendedPortfolios />
      <Spacing y="500" />
      <Advertisement />
      <Popups />
    </StyledHome>
  )
}

export default Home
